@font-face {
  font-family: "Syncopate-Regular";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/SyncopateReg.ttf") format("ttf");
}
@font-face {
  font-family: "Syncopate-Bold";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/SyncopateBold.ttf") format("ttf");
}

@font-face {
  font-family: "NeuzeitGrotesk-light";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/Neuzeit Grotesk/NeuzeitGroTLig.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGrotesk-reg";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neuzeit Grotesk/NeuzeitGrotesk-Reg.woff")
    format("woff");
}
@font-face {
  font-family: "NeuzeitGrotesk-bol";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neuzeit Grotesk/NeuzeitGrotesk-Bol.woff")
    format("woff");
}
@font-face {
  font-family: "NeuzeitGrotesk-bla";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neuzeit Grotesk/NeuzeitGrotesk-Bla.woff")
    format("woff");
}
@font-face {
  font-family: "NeuzeitGrotesk-blaExtCon";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neuzeit Grotesk/NeuzeitGrotesk-BlaExtCon.otf")
    format("woff");
}
@font-face {
  font-family: "NeueMachina-light";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neue Machina/NeueMachina-Light.woff") format("woff");
}
@font-face {
  font-family: "NeueMachina-med";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neue Machina/NeueMachina-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "NeueMachina-bold";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neue Machina/NeueMachina-Bold.woff") format("woff");
}
@font-face {
  font-family: "NeueMachina-reg";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/Neue Machina/NeueMachina-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Monotype Corsiva";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Monotype Corsiva.ttf");
}
@font-face {
  font-family: "Lucida Calligraphy";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/LCALLIG.TTF");
}
@font-face {
  font-family: "Vivaldi";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/VIVALDII.TTF");
}
@font-face {
  font-family: "Calibri";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/calibri.ttf");
}
@font-face {
  font-family: "Arial";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/arial.ttf");
}
@font-face {
  font-family: "Kunstler Script";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/KUNSTLER.TTF");
}
@font-face {
  font-family: "Episode1";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/episode1.ttf");
}
@font-face {
  font-family: "Brush Script MT";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/BRUSHSCI.TTF");
}
@font-face {
  font-family: "Vladimir Script";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/VLADIMIR.TTF");
}
@font-face {
  font-family: "Cambria";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/cambria.ttc") format("ttc");
}
@font-face {
  font-family: "montecarlo";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/MonteCarlo-Regular.otf");
}
@font-face {
  font-family: "Sinoreta";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Sinoreta.otf");
}
@font-face {
  font-family: "GlareLight";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/GlareLight.otf");
}
@font-face {
  font-family: "TangerineReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Tangerine-Regular.ttf");
}
@font-face {
  font-family: "RumbleBrave";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Rumble Brave.ttf");
}
@font-face {
  font-family: "MontserratReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "MontserratBold";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "MontserratLight";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: "PPFragmentReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPFragment-Glare/PPFragment-GlareRegular.ttf");
}
@font-face {
  font-family: "PPEditorialNewUltraLight";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPEditorialNew/PPEditorialNew-Ultralight.ttf");
}
@font-face {
  font-family: "PPEditorialNewUltraLight";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPEditorialNew/PPEditorialNew-Ultralight.ttf");
}
@font-face {
  font-family: "Helvetica";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Helvetica-01.ttf");
}
@font-face {
  font-family: "HelveticaBold";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Helvetica-Bold.ttf");
}
@font-face {
  font-family: "EDITION";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/EDITION.TTF");
}
@font-face {
  font-family: "georgia";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Georgia.ttf");
}
@font-face {
  font-family: "CanvasReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Canvas-Regular.ttf");
}
@font-face {
  font-family: "RobotoThin";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Roboto/Roboto-Thin.ttf");
}
@font-face {
  font-family: "BebasNeueReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "Kindergarten";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Kindergarten.ttf");
}
@font-face {
  font-family: "Medhurst";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Medhurst.ttf");
}
@font-face {
  font-family: "Allison";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Allison.ttf");
}
@font-face {
  font-family: "LibreReg";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Libre/LibreBaskerville-Regular.ttf");
}
@font-face {
  font-family: "LibreItalic";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Libre/LibreBaskerville-Italic.ttf");
}
@font-face {
  font-family: "SansLight";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPFragment-SansLight.otf");
}
@font-face {
  font-family: "PPNeueMontrealBold";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPNeueMontreal/PPNeueMontreal-Bold.ttf");
}
@font-face {
  font-family: "PPNeueMontrealThin";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/PPNeueMontreal/PPNeueMontreal-Thin.ttf");
}
@font-face {
  font-family: "Chopin";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/ChopinScript.ttf");
}
@font-face {
  font-family: "felixtowe";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/felixtowe-Regular.ttf");
}
@font-face {
  font-family: "consola";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/CONSOLA.TTF");
}
@font-face {
  font-family: "consolai";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/consolai.ttf");
}
@font-face {
  font-family: "consolas";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Consolas.ttf");
}
@font-face {
  font-family: "Physis-Grotesk";
  font-display: swap;
  font-style: normal;
  src: url("../public/fonts/Physis-Grotesk.ttf");
}

@font-face {
  font-family: "Alba-Light";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/AlbraTRIAL-Light.otf");
}

@font-face {
  font-family: "Alba-Regular";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/AlbraTRIAL-Regular.otf");
}

@font-face {
  font-family: "Alba-Bold";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/AlbraTRIAL-Bold.otf");
}

@font-face {
  font-family: "Six-Caps";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/SixCaps-Regular.ttf");
}

@font-face {
  font-family: "Freeman";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/Freeman.ttf");
}

@font-face {
  font-family: "MaximumImpact";
  font-display: swap;
  font-style: normal;
  src: url("../public/font-face/NewFont/Maximum_Impact.ttf");
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  /* --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono",
    "Courier New", monospace; */
  --neuzeit-light-font: "NeuzeitGrotesk-light";
  --neuzeit-reg-font: "NeuzeitGrotesk-reg";
  --neuzeit-bol-font: "NeuzeitGrotesk-bol";
  --neuzeit-bla-font: "NeuzeitGrotesk-bla";
  --neuzeit-blaExtCon-font: "NeuzeitGrotesk-blaExtCon";
  --neue-light-font: "NeueMachina-light";
  --neue-reg-font: "NeueMachina-reg";
  --neue-med-font: "NeueMachina-med";
  --neue-bold-font: "NeueMachina-bold";
  --syncopate-reg-font: "Syncopate-Regular";
  --syncopate-bold-font: "Syncopate-Bold";
  --monotype-corsiva-font: "Monotype Corsiva";
  --lucida-calligraphy-font: "Lucida Calligraphy";
  --vivaldi-font: "Vivaldi";
  --calibri-font: "Calibri";
  --arial-font: "Arial";
  --kunstler-script-font: "Kunstler Script";
  --episode-1-font: "Episode1";
  --brush-script-mt-font: "Brush Script MT";
  --vladmir-script-font: "Vladimir Script";
  --cambria-font: "Cambria";
  --montecarlo-font: "Montecarlo";
  --sinoreta-font: "Sinoreta";
  --glare-light-font: "GlareLight";
  --tangerine-reg-font: "TangerineReg";
  --rumble-brave-font: "RumbleBrave";
  --montserrat-reg-font: "MontserratReg";
  --montserrat-bold-font: "MontserratBold";
  --montserrat-light-font: "MontserratLight";
  --ppfragment-reg-font: "PPFragmentReg";
  --ppeditorialnew-ultra-light-font: "PPEditorialNewUltraLight";
  --helvetica-font: "Helvetica";
  --helvetica-bold-font: "HelveticaBold";
  --edition-bold-font: "EDITION";
  --georgia-font: "georgia";
  --canvas-reg-font: "CanvasReg";
  --roboto-thin-font: "RobotoThin";
  --bebas-reg-font: "BebasNeueReg";
  --kindergarten-font: "Kindergarten";
  --medhurst-font: "Medhurst";
  --allison-font: "Allison";
  --libre-reg-font: "LibreReg";
  --libre-italic-font: "LibreItalic";
  --sans-light-font: "SansLight";
  --ppneuemontreal-bold-font: "PPNeueMontrealBold";
  --ppneuemontreal-thin-font: "PPNeueMontrealThin";
  --chopin-font: "Chopin";
  --felixtowe-font: "felixtowe";
  --alba-light-font: "Alba-Light";
  --alba-regular-font: "Alba-Regular";
  --alba-bold-font: "Alba-Bold";
  --freeman-font: "Freeman";

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --margin-layout-container: 10px 80px;
  /* --margin-layout-container: 10px 80px; */
  --container-mobile: 20px 20px;
  --margin-layout-container-right: 10px 80px 10px 25%;
  --margin-layout-container-right-product-page: 10px 80px 10px 20%;
  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  /* --red_color: #ff7e7e; */
  --red_color: #f74c4c;
  --bg_purple: #b0a3d0;
  --bg_white: #fff;
  --bg_black: #000;
  --bg_blue: #a9c3e8;
  --bg_yellow: #dcee47;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
*:focus-visible {
  outline: unset;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: var(--neuzeit-light-font);
}

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb)); */
}
h1,
h2,
h3 {
  font-family: var(--neue-light-font);
}
a {
  color: inherit;
  text-decoration: none;
}
.link div {
  cursor: pointer;
}
a div,
.link div {
  position: relative;
}
a.no-after div::after {
  content: none;
}
a div::after,
.link div::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0%;
  opacity: 0;
  padding-left: 5px;
  height: 0.1px;
  background-color: #000;
  transition: 0.2s all ease;
}
a.dark div::after {
  background-color: #fff;
}
a div:hover::after,
.link div:hover::after {
  width: 95%;
  opacity: 1;
}
button {
  border: none;
  font-family: unset;
}
img {
  object-fit: cover;
}
.text-justify {
  text-align: justify;
}
.my-05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-1 {
  margin-top: 1em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mt-4 {
  margin-top: 4em;
}
.mt-5 {
  margin-top: 5em;
}
.my-5 {
  margin-top: 5em;
  margin-bottom: 5em;
}
.py-5 {
  padding-top: 5em;
  padding-bottom: 5em;
}
.mx-2 {
  margin-right: 2em;
  margin-left: 2em;
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.multiple_img {
  display: flex;
  justify-content: center;
}
.multiple_img div {
  margin: unset !important;
  text-align: center;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
/* main {
  margin: 10px 80px;
} */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 10px;
  z-index: 100;
}
.carousel .control-dots {
  bottom: 40px !important;
  left: 80px !important;
  width: fit-content !important;
}
.container {
  margin: var(--margin-layout-container);
}
.container-large {
  width: 70%;
  margin: 0 auto;
}
.container-right {
  margin: var(--margin-layout-container-right);
}
.container-right-product-page {
  margin: var(--margin-layout-container-right-product-page);
}
.container-mobile {
  margin: var(--container-mobile);
}
.article-width {
  max-width: 800px;
}
.uppercase {
  text-transform: uppercase;
}
.m-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.m-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.monthly-issue-list {
  margin: 5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
hr {
  border-bottom: 0;
  border-top: 1px solid #e1e1e1;
  margin: 10px 0;
}
.button_primary {
  background-color: #dfc8e7;
  width: fit-content;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease all;
  font-weight: 500;
  font-size: 0.9rem;
  color: #101820;
}
.button_primary:hover {
  background-color: #101820;
  color: #fff;
}
.button_disabled {
  background-color: #c5c4c4;
  color: #101820;
  width: fit-content;
  padding: 7px 15px;
  text-transform: uppercase;
  transition: 0.2s ease all;
  font-weight: 500;
  font-size: 0.9rem;
}

.button_grey {
  background-color: #d3d3d3;
  color: #101820;
  width: fit-content;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease all;
  font-weight: 500;
  font-size: 0.9rem;
}
.button_grey:hover {
  background-color: #101820;
  color: #fff;
}
.button_no_bg {
  background-color: transparent;
  color: #101820;
  width: fit-content;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease all;
  font-weight: 500;
  font-size: 0.9rem;
}
/** Status Payment */
.pending {
  background-color: #b3c6ce;
}
.canceled,
.rejected {
  background-color: #f9a050;
}
.processing {
  background-color: #7aa1d5;
}
.completed,
.approved {
  background-color: #68be5d;
  color: #fff;
}
.failed {
  background-color: #f0525c;
  color: #fff;
}
.indelivery {
  background-color: #f0ec74;
}
.text-danger {
  color: #f0525c;
}
/* mui pagination style */
.MuiPaginationItem-root,
.MuiPaginationItem-page {
  height: unset;
  padding: 0;
}
.MuiPaginationItem-root:hover {
  background-color: unset;
}
.MuiPaginationItem-root::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0%;
  opacity: 0;
  padding-left: 5px;
  height: 0.1px;
  background-color: #000;
  transition: 0.2s all ease;
}
.MuiPaginationItem-root:hover::after {
  width: 95%;
  opacity: 1;
}
.MuiPaginationItem-page {
  font-size: 1.1rem;
}
/* .Mui-selected {
  font-family: var(--neuzeit-bol-font);
  background-color: unset !important;
} */
.Mui-selected {
  background-color: #dfc8e7 !important;
  color: #101820 !important;
}
.MuiPaginationItem-firstLast:hover::after {
  opacity: 0;
}
/** SLICK */
.slick-dots {
  bottom: 20px !important;
}
.slick-dots li {
  opacity: 0.75;
  width: 50px !important;
  height: 2px !important;
  background-color: #fff !important;
}
.slick-dots li.slick-active {
  opacity: 1;
}
.slick-dots li button::before {
  display: none;
}

/** Snackbar */
.icon-snackbar {
  margin-right: 0.5rem;
  line-height: 0.6;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: unset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(138, 138, 138);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 51, 51);
}
/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.loading-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 100000;
}
.loading-wrapper img {
  width: 150px;
  height: auto;
}
.whats_new_menu {
  background-color: #dfc8e7;
  padding: 0.1vw 0.5vw;
}
.last_change {
  font-weight: 900;
  display: none;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.comingSoon {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: relative;
}

.comingSoon_text {
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5em;
  font-weight: 900;
  width: 100%;
  text-align: center;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.basis-1\/3 {
  flex-basis: 33.333%;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

/** BREAKPOINT */
@media only screen and (min-width: 1600px) {
  .container-right {
    margin: 10px 80px 10px 16%;
  }
}

@media only screen and (max-width: 1420px) {
  .container-right {
    margin: 10px 80px 10px 23%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .container-right-product-page {
    margin: 10px 80px 10px 15%;
  }
  .container-right {
    margin: 10px 80px 10px 20%;
  }
}
@media only screen and (max-width: 650px) {
  .article-width {
    max-width: 100%;
  }

  .comingSoon_text {
    font-size: 3em;
  }
}
